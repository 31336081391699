jQuery(document).ready(function($){
	//Standard Fade in

	$fadeIns = Array.from(document.querySelectorAll('.fade-in'));

	$fadeOptions = {
		threshold: 0.25
	}

	var fadeInObserver = new IntersectionObserver(function(fadeIns){
		fadeIns.forEach(function(fade){
			if(fade.isIntersecting){
				fade.target.classList.add('animated');
			}
		})
	}, $fadeOptions)

	for($i = 0; $i < $fadeIns.length; $i++){
		fadeInObserver.observe($fadeIns[$i])
	}

	//Recursive fade in - makes each child of element with class fade in independently

	//This selector can be changed if you want to go down additional layers etc.

	$childFadeIns = Array.from(document.querySelectorAll('.child-fade-in>*'));

	$(window).resize(function(){
		$childFadeIns.forEach(function(child){
			child.classList.add('animated');
		})
	})

	//Code repeated here, so that threshold can be amended without affecting standard fade-ins. Threshold set artificially high to show effect

	$childFadeOptions = {
		threshold: 0.75
	}

	var childFadeInObserver = new IntersectionObserver(function(fadeIns){
		fadeIns.forEach(function(fade){
			if(fade.isIntersecting){
				fade.target.classList.add('animated');
			}
		})
	}, $childFadeOptions)

	for($i = 0; $i < $childFadeIns.length; $i++){
		childFadeInObserver.observe($childFadeIns[$i])
	}


	//Delayed fade in - makes each child fade in after a set time - useful for a row of cards, or an archive

	$delayedFadeIns = Array.from(document.querySelectorAll('.delayed-fade-in'));

	//Threshold set artificially high to show effect

	$delayedOptions = {
        threshold: 0.5
    }

    //One observer, which triggers multiple animations. Fundamental difference between this and child fade

    function delayedTimeout($j, $child){
    	setTimeout(function(){
	        $child.classList.add('animated');
	    }, $j*800)
    }

	var delayedFadeObserver = new IntersectionObserver(function(entries){
	    entries.forEach(function(entry){
	        if(entry.isIntersecting) {
	            var children = entry.target.children
	            for($j = 0; $j < children.length; $j++){
	                delayedTimeout($j, children[$j]);
	            } 
	        }
	    });
	}, $delayedOptions);

	for($i = 0; $i < $delayedFadeIns.length; $i++){
		delayedFadeObserver.observe($delayedFadeIns[$i])
	}


});